import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex: 1;\n  border: 1px solid #ccc;\n  border-radius: ",
        ";\n  flex-direction: column;\n  margin-left: 20px;\n  cursor: pointer;\n\n  @media (min-width: ",
        ") {\n    margin-left: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-left: 0;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  overflow: hidden;\n  height: 150px;\n  border-radius: ",
        ";\n  position: relative;\n\n  img {\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    height: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    height: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  width: calc(100% - 48px);\n  margin: 0 auto;\n  height: calc(100% - 38px);\n  margin-top: 22px;\n  position: relative;\n\n  & > div,\n  & > h2 {\n    position: absolute;\n    left: 0;\n  }\n\n  & > div {\n    top: 0;\n  }\n\n  & > h2 {\n    bottom: 0;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin: 0;\n  color: white;\n  z-index: 1;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  border-bottom-right-radius: 20px;\n  border-bottom-left-radius: 20px;\n  background: ",
        ";\n  backdrop-filter: blur(40px);\n  min-height: 280px;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin-left: 24px;\n  margin-right: 24px;\n  margin-bottom: 32px;\n\n  @media (min-width: ",
        ") {\n    margin-left: 20px;\n    margin-right: 20px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-left: 20px;\n    margin-right: 20px;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  margin-top: 32px;\n\n  h3 {\n    margin: 0;\n    font-family: ",
        ";\n    color: ",
        ";\n    font-weight: 400;\n    ",
        "\n  }\n\n  p {\n    margin: 0;\n    margin-top: 24px;\n    font-family: ",
        ";\n    color: ",
        ";\n    font-weight: 400;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    p {\n      ",
        "\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    h3 {\n      ",
        "\n    }\n\n    p {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    margin-top: 24px;\n    ",
        "\n    color: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      margin: 0;\n      margin-top: 24px;\n    }\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  margin-left: 24px;\n  margin-right: 24px;\n  margin-bottom: 32px;\n\n  @media (min-width: ",
        ") {\n    margin-left: 20px;\n    margin-right: 20px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-left: 20px;\n    margin-right: 20px;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from "@vfit/shared/themes";
export var PassionCardContainer = styled.div.withConfig({
    componentId: "sc-58c5f74d-0"
})(_templateObject(), function(param) {
    var isApp = param.isApp;
    return "".concat(isApp ? "6px" : "20px");
}, breakpoints.tablet, breakpoints.desktop);
export var PassionCardTopImage = styled.div.withConfig({
    componentId: "sc-58c5f74d-1"
})(_templateObject1(), function(param) {
    var isApp = param.isApp;
    return "".concat(isApp ? "6px 6px 0 0" : "20px 20px 0 0");
}, breakpoints.tablet, function(param) {
    var large = param.large;
    return large ? "400px" : "200px";
}, breakpoints.desktop, function(param) {
    var large = param.large;
    return large ? "400px" : "200px";
});
export var PassionCardTopImageJiustifyContentBox = styled.div.withConfig({
    componentId: "sc-58c5f74d-2"
})(_templateObject2());
export var TitleAboveImg = styled.h2.withConfig({
    componentId: "sc-58c5f74d-3"
})(_templateObject3(), pxToCssFont(28, 36));
export var PassionCardBottom = styled.div.withConfig({
    componentId: "sc-58c5f74d-4"
})(_templateObject4(), colors.$ffffff);
export var PassionCardBottomContainer = styled.div.withConfig({
    componentId: "sc-58c5f74d-5"
})(_templateObject5(), breakpoints.tablet, breakpoints.desktop);
export var PassionCardBottomContainerText = styled.div.withConfig({
    componentId: "sc-58c5f74d-6"
})(_templateObject6(), fonts.exbold, colors.$0d0d0d, pxToCssFont(30, 38), fonts.regular, colors.$0d0d0d, pxToCssFontSize(18), breakpoints.tablet, pxToCssFontSize(18), breakpoints.desktop, pxToCssFont(36, 45), pxToCssFontSize(18));
export var TextContainer = styled.div.withConfig({
    componentId: "sc-58c5f74d-7"
})(_templateObject7(), function(param) {
    var lineHeight = param.lineHeight;
    return pxToCssFont(18, lineHeight || 22);
}, colors.$262626, breakpoints.desktop);
export var PassionAction = styled.div.withConfig({
    componentId: "sc-58c5f74d-8"
})(_templateObject8(), breakpoints.tablet, breakpoints.desktop);
