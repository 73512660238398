import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { useCallback, useEffect, useId, useMemo, useRef, useState } from "react";
import { stringToAsciiCode } from "@vfit/shared/themes";
import { createSyntheticEvent, useAnimation, useDeviceType, useOnClickOutside } from "@vfit/shared/hooks";
export var useSelect = function(param) {
    var _btnProps = param._btnProps, _onSvgAnimationEnd = param._onSvgAnimationEnd, _svg = param._svg, _svgClassName = param._svgClassName, children = param.children, defaultValue = param.defaultValue, disableFiltering = param.disableFiltering, items = param.items, label = param.label, onBlur = param.onBlur, onChange = param.onChange, onClick = param.onClick, ref = param.ref, unordered = param.unordered, value = param.value, variant = param.variant, hideFilteredItems = param.hideFilteredItems;
    var containerRef = useRef(null);
    var innerRef = useRef(null);
    var ref1 = useState(null), errorNoSelection = ref1[0], setErrorNoSelection = ref1[1];
    var ref2 = useState(unordered ? items : _to_consumable_array(items).sort(function(first, second) {
        return first.localeCompare(second, undefined, {
            numeric: true
        });
    })), filteredItems = ref2[0], setFilteredItems = ref2[1];
    var ref3 = useState(false), open = ref3[0], setOpen = ref3[1];
    var ref4 = useState(defaultValue || value), output = ref4[0], setOutput = ref4[1];
    var close = useCallback(function() {
        setOpen(false);
    }, []);
    useOnClickOutside(containerRef.current, close);
    var isMobile = useDeviceType().isMobile;
    var id = useId();
    var itemSize = useMemo(function() {
        return variant && !variant.startsWith("mva") ? 40 : 50;
    }, [
        variant
    ]);
    var svg = _svg || "chevronDown";
    var arrowA = useAnimation({
        prefix: "arrow",
        show: open,
        skipOnMount: true
    });
    var optionsThingsToWait = useMemo(function() {
        return {
            filteredItems: filteredItems,
            children: children
        };
    }, [
        children,
        filteredItems
    ]);
    var optionsA = useAnimation({
        show: open && (filteredItems.length > 0 || !!children),
        thingsToWait: optionsThingsToWait
    });
    var svgClassName = useMemo(function() {
        return svg === "chevronDown" ? arrowA.animationClass : _svgClassName;
    }, [
        _svgClassName,
        arrowA.animationClass,
        svg
    ]);
    var onSvgAnimationEnd = useCallback(function() {
        return _onSvgAnimationEnd || arrowA.onAnimationEnd;
    }, [
        _onSvgAnimationEnd,
        arrowA.onAnimationEnd
    ]);
    var refCallback = useCallback(function(node) {
        if (node) {
            innerRef.current = node;
            if (ref) {
                if (typeof ref === "function") {
                    ref(node);
                } else {
                    // eslint-disable-next-line no-param-reassign
                    ref.current = node;
                }
            }
        }
    }, [
        ref
    ]);
    var getFilteredItems = useCallback(function(text) {
        if (!text) {
            setErrorNoSelection(null);
            return items;
        }
        var t = text.toLowerCase();
        var localFilteredItems = items === null || items === void 0 ? void 0 : items.filter(function(s) {
            return s.toLowerCase().indexOf(t) !== -1;
        }).sort(function(first, second) {
            var f = first.toLowerCase();
            var s = second.toLowerCase();
            if (f.startsWith(t) && !s.startsWith(t)) {
                return -1;
            }
            if (!f.startsWith(t) && s.startsWith(t)) {
                return 1;
            }
            if (f < s) {
                return -1;
            }
            if (f > s) {
                return 1;
            }
            return 0;
        });
        // No filtered Element set erro
        if (localFilteredItems.length === 0) {
            setErrorNoSelection('Nessun risultato per "'.concat(text, '"'));
        } else {
            setErrorNoSelection(null);
        }
        return localFilteredItems;
    }, [
        items
    ]);
    var handleChange = useCallback(function(e) {
        setFilteredItems(getFilteredItems(e.target.value));
        setOutput(e.target.value);
        onChange === null || onChange === void 0 ? void 0 : onChange(e);
    }, [
        getFilteredItems,
        onChange
    ]);
    var onSelect = useCallback(function(e) {
        if (innerRef.current) {
            // eslint-disable-next-line no-param-reassign
            innerRef.current.value = e.currentTarget.getAttribute("value") || "";
            var syntheticEvent = createSyntheticEvent(innerRef.current, "change");
            if (syntheticEvent) {
                handleChange === null || handleChange === void 0 ? void 0 : handleChange(syntheticEvent);
            }
            // This is a trick to force an update in the input
            setTimeout(function() {
                var ref, ref1;
                innerRef === null || innerRef === void 0 ? void 0 : (ref = innerRef.current) === null || ref === void 0 ? void 0 : ref.focus();
                innerRef === null || innerRef === void 0 ? void 0 : (ref1 = innerRef.current) === null || ref1 === void 0 ? void 0 : ref1.blur();
                close();
            }, 1);
        }
    }, [
        close,
        handleChange
    ]);
    var handleBlur = useCallback(function(e) {
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
    }, [
        onBlur
    ]);
    var toggleOpen = useCallback(function() {
        setOpen(function(o) {
            return !o;
        });
    }, []);
    var handleClickInput = useCallback(function(e) {
        if (!open) {
            setOpen(true);
        }
        onClick === null || onClick === void 0 ? void 0 : onClick(e);
    }, [
        onClick,
        open
    ]);
    var handleClickInputAndButton = useCallback(function(e) {
        toggleOpen();
        onClick === null || onClick === void 0 ? void 0 : onClick(e);
    }, [
        onClick,
        toggleOpen
    ]);
    var getItemKey = useCallback(function(index, data) {
        return stringToAsciiCode(data[index]);
    }, []);
    var btnProps = useMemo(function() {
        return !(_btnProps === null || _btnProps === void 0 ? void 0 : _btnProps.onClick) ? _object_spread_props(_object_spread({}, _btnProps), {
            "aria-controls": id,
            "aria-expanded": open,
            "aria-label": label,
            onClick: toggleOpen,
            tabIndex: -1
        }) : _object_spread({
            "aria-controls": id,
            "aria-label": label
        }, _btnProps);
    }, [
        _btnProps,
        id,
        open,
        toggleOpen,
        label
    ]);
    useEffect(function() {
        if (innerRef.current && items) {
            setFilteredItems(getFilteredItems(innerRef.current.value));
            setOutput(innerRef.current.value);
        }
    }, [
        getFilteredItems,
        items
    ]);
    return {
        arrowA: arrowA,
        btnProps: btnProps,
        close: close,
        containerRef: containerRef,
        getItemKey: getItemKey,
        handleBlur: handleBlur,
        handleChange: handleChange,
        handleClickInput: handleClickInput,
        handleClickInputAndButton: handleClickInputAndButton,
        id: id,
        innerRef: innerRef,
        isMobile: isMobile,
        itemSize: itemSize,
        onSelect: onSelect,
        onSvgAnimationEnd: onSvgAnimationEnd,
        open: open,
        optionsA: optionsA,
        output: output,
        refCallback: refCallback,
        svg: svg,
        svgClassName: svgClassName,
        errorNoSelection: errorNoSelection
    };
};
