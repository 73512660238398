import { checkWindow } from "@vfit/shared/data-access";
import { capitalize } from "lodash-es";
var retrieveBasePageName = function(cart_product, product_name) {
    var ref;
    if (!checkWindow()) return "";
    var hostname = window.location.hostname.split(".");
    var pageType = hostname[0] === "www" ? hostname[1] : hostname[0];
    var path = window.location.pathname.split("/").filter(function(x) {
        return Boolean(x) && !x.startsWith("[");
    }).map(function(x) {
        return capitalize(x);
    }).join(":");
    var pathWithoutParams = path === null || path === void 0 ? void 0 : path.split("?");
    var pageNameToReturn = pageType ? "VFIT:".concat(path ? "".concat(pageType, ":").concat(path) : pageType) : "VFIT:".concat((pathWithoutParams === null || pathWithoutParams === void 0 ? void 0 : pathWithoutParams[0]) || "");
    var prod_name_in_url = (cart_product === null || cart_product === void 0 ? void 0 : (ref = cart_product.cart_product_name) === null || ref === void 0 ? void 0 : ref.toLowerCase()) || (product_name === null || product_name === void 0 ? void 0 : product_name.toLowerCase());
    if (prod_name_in_url) {
        return pageNameToReturn === null || pageNameToReturn === void 0 ? void 0 : pageNameToReturn.replace(new RegExp("".concat(prod_name_in_url, ":"), "ig"), "");
    }
    return pageNameToReturn;
};
var retrievePageChannel = function(pageType) {
    switch(pageType.toLowerCase()){
        case "privati":
        case "landing":
        case "vodafone":
            return "consumer";
        case "business" || "grandiaziende":
            return "business";
        default:
            return "na_in_test_env";
    }
};
var shouldTrack = function(event) {
    var ref;
    var forceTracking = event === "tool_start" || event === "tool_end";
    var queryString = new URLSearchParams(window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.search);
    var queryParamsDisabled = queryString.get("act");
    return !queryParamsDisabled || forceTracking;
};
export { shouldTrack, retrievePageChannel, retrieveBasePageName };
