import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  padding: 0 2px 0 0;\n  border-radius: 6px;\n  border-color: ",
        ";\n  background: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  padding: 1rem;\n  border: none;\n\n  & svg {\n    vertical-align: middle;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  text-align: start;\n  font-family: ",
        ";\n  ",
        "\n  padding: 1rem;\n  background: ",
        ";\n  width: 100%;\n  border-top: 2px solid;\n  border-bottom: 2px solid;\n  border-color: inherit;\n  border-radius: 0 6px 6px 0;\n\n  p {\n    margin: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var StyledAlert = styled.div.attrs(function(props) {
    return {
        type: props.type
    };
}).withConfig({
    componentId: "sc-12b86347-0"
})(_templateObject(), function(props) {
    var color;
    switch(props.type){
        case "success":
            color = colors.$008a00;
            break;
        case "error":
            color = colors.$bd0000;
            break;
        case "warning":
            color = "#eb9700";
            break;
        default:
            color = "#005ea5";
            break;
    }
    return color;
}, function(props) {
    var color;
    switch(props.type){
        case "success":
            color = colors.$008a00;
            break;
        case "error":
            color = colors.$bd0000;
            break;
        case "warning":
            color = "#eb9700";
            break;
        default:
            color = "#005ea5";
            break;
    }
    return color;
});
export var StyledIconContainer = styled.div.withConfig({
    componentId: "sc-12b86347-1"
})(_templateObject1());
export var StyledText = styled.div.withConfig({
    componentId: "sc-12b86347-2"
})(_templateObject2(), fonts.regular, pxToCssFont(16, 22), colors.$ffffff, breakpoints.tablet, pxToCssFont(18, 24));
