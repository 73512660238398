import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: 45px;\n  div {\n    span {\n      display: ",
        ";\n      text-transform: ",
        ";\n      font-weight: ",
        ";\n      font-size: ",
        ";\n    }\n    span::first-letter {\n      text-transform: uppercase;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    width: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    width: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
var ButtonSlideCTA = styled.div.withConfig({
    componentId: "sc-849bb613-0"
})(_templateObject(), function(param) {
    var isApp = param.isApp;
    return isApp ? "inline-block" : "";
}, function(param) {
    var isApp = param.isApp;
    return isApp ? "lowercase" : "";
}, function(param) {
    var isApp = param.isApp;
    return isApp ? "400" : "";
}, function(param) {
    var isApp = param.isApp;
    return isApp ? "16px" : "";
}, breakpoints.mobile, function(param) {
    var isApp = param.isApp;
    return "".concat(isApp ? "100%" : "207px");
}, breakpoints.desktop, function(param) {
    var isApp = param.isApp;
    return "".concat(isApp ? "100%" : "324px");
});
export default ButtonSlideCTA;
