import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-top: 48px;\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    justify-content: center;\n    text-align: center;\n\n    div {\n      text-align: center;\n      min-width: 319px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    justify-content: center;\n    text-align: center;\n\n    div {\n      text-align: center;\n      min-width: 319px;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  div {\n    span {\n      display: ",
        ";\n      text-transform: ",
        ";\n      font-weight: ",
        ";\n      font-size: ",
        ";\n    }\n    span::first-letter {\n      text-transform: uppercase;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  color: ",
        ";\n  margin-bottom: 20px;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-ffad89af-0"
})(_templateObject(), breakpoints.desktop, breakpoints.tablet);
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-ffad89af-1"
})(_templateObject1(), function(param) {
    var isApp = param.isApp;
    return isApp ? "inline-block" : "inherit";
}, function(param) {
    var isApp = param.isApp;
    return isApp ? "lowercase" : "inherit";
}, function(param) {
    var isApp = param.isApp;
    return isApp ? "400" : "inherit";
}, function(param) {
    var isApp = param.isApp;
    return isApp ? "16px" : "inherit";
});
export var Title = styled.p.withConfig({
    componentId: "sc-ffad89af-2"
})(_templateObject2(), fonts.exbold, pxToCssFont(30, 38), colors.$262626);
