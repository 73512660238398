import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useCountDown } from "@vfit/consumer/hooks";
var HeaderCountdown = function(param) {
    var date = param.date;
    if (!date) return null;
    var now = new Date();
    var limit = new Date(date);
    var diffInSec = Math.floor((limit.getTime() - now.getTime()) / 1000);
    var ref = _sliced_to_array(useCountDown(diffInSec), 4), day = ref[0], hour = ref[1], minute = ref[2], second = ref[3];
    var dataDays = document.getElementById("data-days");
    var dataDaysText = document.getElementById("data-days-text");
    var dataHours = document.getElementById("data-hours");
    var dataHoursText = document.getElementById("data-hours-text");
    var dataMinutes = document.getElementById("data-minutes");
    var dataMinutesText = document.getElementById("data-minutes-text");
    var dataSeconds = document.getElementById("data-seconds");
    var dataSecondsText = document.getElementById("data-seconds-text");
    // region LABEL GIORNI/GIORNO MINUTI/MINUTO ORE/ORA SECONDO/SECONDI
    if ((dataDaysText === null || dataDaysText === void 0 ? void 0 : dataDaysText.innerText) === "giorni" || (dataDaysText === null || dataDaysText === void 0 ? void 0 : dataDaysText.innerText) === "giorno") {
        if (parseInt(day, 10) === 1) {
            dataDaysText.innerText = "giorno";
        } else {
            dataDaysText.innerText = "giorni";
        }
    }
    if ((dataHoursText === null || dataHoursText === void 0 ? void 0 : dataHoursText.innerText) === "ore" || (dataHoursText === null || dataHoursText === void 0 ? void 0 : dataHoursText.innerText) === "ora") {
        if (parseInt(hour, 10) === 1) {
            dataHoursText.innerText = "ora";
        } else {
            dataHoursText.innerText = "ore";
        }
    }
    if ((dataMinutesText === null || dataMinutesText === void 0 ? void 0 : dataMinutesText.innerText) === "minuti" || (dataMinutesText === null || dataMinutesText === void 0 ? void 0 : dataMinutesText.innerText) === "minuto") {
        if (parseInt(minute, 10) === 1) {
            dataMinutesText.innerText = "minuto";
        } else {
            dataMinutesText.innerText = "minuti";
        }
    }
    if ((dataSecondsText === null || dataSecondsText === void 0 ? void 0 : dataSecondsText.innerText) === "secondi" || (dataSecondsText === null || dataSecondsText === void 0 ? void 0 : dataSecondsText.innerText) === "secondo") {
        if (parseInt(second, 10) === 1) {
            dataSecondsText.innerText = "secondo";
        } else {
            dataSecondsText.innerText = "secondi";
        }
    }
    // endregion
    if (dataDays) dataDays.innerText = day;
    if (dataHours) dataHours.innerText = hour;
    if (dataMinutes) dataMinutes.innerText = minute;
    if (dataSeconds) dataSeconds.innerText = second;
    return null;
};
export default HeaderCountdown;
