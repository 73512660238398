/**
 *  API CLASS
 */ export var API = {
    // region Services
    SILENT_LOGIN: "authentication/external-user/v1/silentLogin",
    ACCOUNT_SELECTION: "authentication/external-user/v1/accountSelection",
    CARE_V1: "care/v1",
    OFFERING_SERVICEABILITY: "commerce/v1/offeringServiceability",
    ADDRESS: "care/v1/address",
    VALIDATE_ADDRESS: "care/v1/validateAddress",
    REPLACE_BILLING_ADDRESS: "billingCare/v1/billingAccount",
    SHOPPING_CART: "commerce/v1/shoppingCart",
    CHECK_CART_IN_COOKIE: "checkCartInCookie",
    RETRIEVE_CART_IN_COOKIE: "retrieveCartFromCookie",
    CART_ITEM: "cartItem",
    CUSTOMER: "care/v1/customer",
    FIND_CALLER: "care/v1/findCaller",
    COUNTRY: "care/v1/country",
    FISCAL_CODE: "care/v1/fiscalCodeBirthPlace",
    COEXISTENCE_USER: "commerce/v1/coexistenceuser",
    CUSTOMER_REFERENCE_DATA: "care/v1/customerReferenceData",
    ASSOCIATE_CUSTOMER: "/associateCustomer",
    LINK_CONTACT_CUSTOMER: "care/v1/linkContactAndCustomer",
    INDIVIDUAL: "care/v1/individual",
    ORDER: "commerce/v1/order",
    ORDER_V2: "commerce/v2/order",
    PAYMENT_MANAGEMENT_ORDER: "commerce/v1/paymentManagement/order",
    UPDATE_PRODUCT_CHARACTERISTIC: "updateProductCharacteristic",
    MODIFY_MOBILE_SIM_DEVICE: "modifyMobileSimDevice",
    UPDATE_PRODUCT_CHARACTERISTIC_LIGHT: "updateProductCharacteristicLight",
    CHECKOUT: "checkout",
    DELIVERY_DETAILS: "/deliveryDetails",
    RESERVE: "commerce/v1/phoneNumber/reserve",
    RELEASE_RESOURCE: "commerce/v1/phoneNumber/releaseResource",
    BILLING_ACCOUNT: "billingAccount",
    NOTIFY: "notify",
    BILLING_DELIVERY_DETAILS: "billDeliveryDetails",
    PAYMENT: "payment",
    PAYMENT_METHOD: "paymentMethod",
    PAYMEAN: "payMean",
    ORDER_TRACKING: "orderTrackingDetails",
    AUTHORIZE: "authorize",
    AUTHORIZATION: "authorization",
    PAYMENT_DISTRIBUTION: "paymentDistribution",
    RESERVE_EQUIPMENT: "reserveEquipment",
    VALIDATE_IBANS: "validateIbans",
    LIGHT_CREDIT_VETTING: "lightCreditVetting",
    SUBMIT: "submit",
    SUBMIT_WITH_PAYMENT: "submitWithPayment",
    SEND_EMAIL_NOTIFICATION: "sendEmailNotification",
    MULTI_PLAYING_OFFERING_CONFIGURATION: "commerce/v1/multiPlayOfferingConfiguration",
    VALIDATE_DUPLICATE_ORDER: "commerce/v1/validateDuplicateOrder",
    CUSTOMER_REGISTRATION: "tmfdigitalidentity/tmf-api/digitalIdentityManagement/v4/digitalIdentity",
    GENERATE: "care/v1/otp/generate",
    VERIFY: "care/v1/otp/verify",
    SEARCH_SUBSCRIBER_BY_RESOURCE: "billingcare/v1/searchSubscriberByResource",
    CHECK_IN_PORT: "commerce/v1/checkPortInFeasibilityII",
    GET_PORTABILITY_INFO: "commerce/v1/portininfo",
    MODIFY_PHONE_NUMBER_PRODUCT: "modifyPhoneNumberProduct",
    PRIVACY_PROFILE: "privacyProfile",
    RETRIEVE_MULTILINES: "privacyProfile/retrieveMultiLines",
    SEARCH_PICKUP_LOCATIONS: "searchPickUpLocations",
    LOCATE_STORE: "locateStores",
    CAMPAIGNS: "commerce/v1/campaigns",
    CAMPAIGN: "commerce/v1/campaign",
    ACCEPT: "accept",
    UNFREEZE: "unfreeze",
    CHECK_REFUND: "landing/checkrefund",
    ACTIVABLE_PRODUCTS: "journeycatalog/v2/activableproducts",
    DECRYPT_DATA: "eshop/decryptdata",
    DXL_CUSTOMER: "CustomerManagement/v2/customer",
    VIDEO_RECOGNITION: "eshop/videoRecognition",
    VOLA_CLIENT: "eshop/volaclient",
    CREATE_SLOT_TIME: "eshop/createSlotTime",
    MOVE_APPOINTMENT: "eshop/moveAppointment",
    SIM_TYPE: "commerce/v1/simInformation",
    USER: "authentication/v1/user",
    LOGIN_ENRICHMENT: "authorization/v1/loginByNetworkEnrichment",
    LOGIN_ENRICHMENT_V2: "authorization/v2/loginByNetworkEnrichment",
    ATTACHMENT_LIST: "care/v1/attachment/list",
    ATTACHMENT_DOWNLOAD_DOCUMENT: "care/v1/attachment/download",
    RETRIEVE_DYNAMIC_ATTRIBUTES: "/retrieveDynamicAttributes",
    DECRYPT_DATA_RESHAPING: "common/v1/decrypt",
    CUSTOMER_APROVAL_SELECTION: "confirmCustomerAprovalSelection",
    PARTNER_OFFER_VALIDATE: "commerce/v1/partnerOffer/fiscal/validate",
    DXL_IDENTIFY: "customeridentification/v1/identify",
    DXL_LOGIN_BY_USER_AND_PASS: "authorization/v1/loginByUsernameAndPassword",
    DXL_LOGIN_BY_USER_AND_PASS_V2: "authorization/v2/loginByUsernameAndPassword",
    DXL_ACTIVE_PRODUCT_LIST: "catalog/v1/retrieveCustomerProducts",
    DXL_ACTIVABLE_PRODUCTS: "catalog/v1/retrieveActivableProducts",
    DXL_GET_ITEMS: "items/v1/retrieveItems",
    DXL_ACTIVATE_PRODUCTS: "catalog/v1/activateProducts",
    DXL_REGISTER_DEVICE: "device/v1/registerDevice",
    DXL_USER_APP_V1: "authorization/v1/user",
    DXL_USER_ENRICHED_CHALLENGE_APP_V1: "authorization/v1/retrieveNetworkEnrichmentChallenge",
    DXL_VALIDATE_MFA_ENRICHMENT_CHALLENGE_AND_LOGIN_V1: "authorization/v1/validateNetworkEnrichmentChallenge",
    DXL_VALIDATE_MFA_CHALLENGE_AND_LOGIN_V2: "authorization/v2/validateMfaChallengeAndLogin",
    VOUCHER: "voucher",
    VALIDATE_VOUCHER: "validateVoucher",
    DXL_CREATE_FACTOR: "authenticator/v2/user",
    DXL_VALIDATE: "authenticator/v2",
    // endregion
    // region FlowMobileLine
    CMS_GET_DELIVERY_INFORMATION_MOBILE: "consumer/eshop/mobile-line/common/delivery-information",
    CMS_GET_PAYMENTS_MOBILE: "consumer/eshop/mobile-line/common/payments-information",
    CMS_GET_PERSONAL_DATA_MOBILE: "consumer/eshop/mobile-line/common/personal-data",
    CMS_GET_PORTABILITY_MOBILE: "consumer/eshop/mobile-line/common/portability",
    CMS_GET_TERMS_AND_CONDITIONS_MOBILE: "consumer/eshop/mobile-line/common/terms-and-conditions",
    CMS_GET_SUPPORT_MODULE_MOBILE: "consumer/eshop/mobile-line/common/support-module",
    CMS_GET_THANK_YOU_PAGE_MOBILE: "consumer/eshop/mobile-line/common/thank-you-page",
    CMS_GET_GENERIC_ERROR_MOBILE: "consumer/eshop/mobile-line/common/generic-error",
    CMS_GET_ORDER_ERROR_MOBILE: "consumer/eshop/mobile-line/common/order-errors",
    CMS_GET_CUSTOMER_ERROR_MOBILE: "consumer/eshop/mobile-line/common/customer-errors",
    CMS_GET_EDIT_ADDRESS_MOBILE: "consumer/eshop/mobile-line/common/edit-address",
    CMS_GET_MAP_MOBILE: "consumer/eshop/mobile-line/common/store-map",
    CMS_GET_EDIT_ADDRESS_STEPPER_MOBILE: "consumer/eshop/mobile-line/common/edit-address-store",
    CMS_GET_WINBACK_DATA: "consumer/eshop/mobile-line/common/winback",
    CMS_GET_WINBACK_CONFIG: "consumer/eshop/mobile-line/campaigns-configurations/all",
    CMS_GET_FAMILY_PLUS_PAYMENT: "consumer/eshop/mobile-line/common/familyPlus/payments",
    CMS_GET_MOBILE_VOUCHERS: "consumer/eshop/mobile-line/common/vouchers",
    CMS_GET_VOUCHER_INSERTION_MANAGMENT_CONF: "consumer/eshop/fixed-line/common/voucher-apply",
    // endregion
    // region FlowFixedLine
    CMS_GET_COVERAGE_TOOL_CONFIG: "consumer/eshop/fixed-line/common/coverage-tool",
    CMS_GET_PERSONAL_DATA: "consumer/eshop/fixed-line/common/personal-data",
    CMS_GET_BILLING_INFORMATION: "consumer/eshop/fixed-line/common/billing-information",
    CMS_GET_SUPPORT_MODULE: "consumer/eshop/fixed-line/common/support-module",
    CMS_GET_TERMS_AND_CONDITIONS: "consumer/eshop/fixed-line/common/terms-and-conditions",
    CMS_GET_PORTABILITY: "consumer/eshop/fixed-line/common/portability",
    CMS_GET_DELIVERY_INFORMATION: "consumer/eshop/fixed-line/common/delivery-information",
    CMS_GET_PAYMENTS: "consumer/eshop/fixed-line/common/payments-information",
    CMS_GET_THANK_YOU_PAGE: "consumer/eshop/fixed-line/common/thank-you-page",
    CMS_GET_MAP: "consumer/eshop/fixed-line/common/store-map",
    CMS_GET_TECNOLOGIES: "consumer/eshop/fixed-line/common/technologies/all",
    CMS_GET_DEVICES: "consumer/eshop/fixed-line/common/devices/all",
    CMS_GET_GENERIC_ERROR: "consumer/eshop/fixed-line/common/generic-error",
    CMS_GET_ORDER_ERROR: "consumer/eshop/fixed-line/common/order-errors",
    CMS_GET_CUSTOMER_ERROR_FIXED: "consumer/eshop/fixed-line/common/customer-errors",
    CMS_GET_EDIT_ADDRESS: "consumer/eshop/fixed-line/common/edit-address",
    CMS_CONTRACT_PATH: "content/dam/myvf/mva10/cce-pdf",
    CMS_GET_EDIT_ADDRESS_STEPPER: "consumer/eshop/fixed-line/common/edit-address-store",
    CMS_GET_DROPPED_CARD: "consumer/eshop/fixed-line/common/dropped-cart",
    CMS_GET_DROPPED_CART_HOMEPAGE: "consumer/eshop/fixed-line/common/dropped-cart-homepage",
    CMS_GET_CLICK_TO_CALL_CONF: "consumer/eshop/fixed-line/common/click-to-call",
    CMS_GET_FIXED_VOUCHERS: "consumer/eshop/fixed-line/common/vouchers",
    CMS_GET_VOUCHER_DISCOUNT: "consumer/eshop/fixed-line/common/voucherdiscount",
    // endregion
    // region consumer Globals
    CMS_GET_BRIGHT_SKY_CONF: "consumer/global/bright-sky",
    CMS_GET_GLOBAL_CONFIG: "consumer/global/global-config",
    CMS_GET_STICKY_BANNER: "consumer/global/header-sticky-banner",
    CMS_GET_FLOATING_BANNER: "consumer/global/floating-banner",
    CMS_GET_GLOBAL_PROMO: "consumer/global/global-promo",
    // endregion
    // region LandingPage
    CMS_GET_FATTURAZIONE2830: "landing/fatturazione-2830",
    CMS_GET_VIDEO_RECOGNITION: "landing/video-recognition",
    CMS_GET_RESHAPING_ALL: "landing/reshaping-offer/all",
    CMS_GET_RESHAPING_PRODUCT_CARDS: "landing/reshaping-offer/product-cards",
    CMS_GET_ONBOARDING_VIEWS_COMMON: "landing/onboarding/views/common",
    CMS_GET_ONBOARDING_STEPPERCARDS_COMMON: "landing/onboarding/dashboard/steppercards/common",
    CMS_GET_ONBOARDING_MODULES_COMMON: "landing/onboarding/dashboard/modules/common",
    CMS_GET_ONBOARDING_DASHBOARD_COMMON: "landing/onboarding/dashboard/common",
    CMS_GET_ONBOARDING_WIZARD_TVBOX: "landing/onboarding/wizards/tv-box",
    CMS_GET_ONBOARDING_WIZARD_POWERSTATION: "landing/onboarding/wizards/power-station",
    CMS_GET_ONBOARDING_WIZARD_POWERWIFI: "landing/onboarding/wizards/power-wifi",
    CMS_GET_ONBOARDING_WIZARD_WIFIEXTENDER: "landing/onboarding/wizards/wifi-extender",
    CMS_GET_ONBOARDING_WIZARD_INVOICE_HELP: "landing/onboarding/wizards/invoice-help",
    CMS_GET_ONBOARDING_WIZARD_ALTERNATIVE_MODEM_FTTH: "landing/onboarding/wizards/alternative-modem-ftth",
    CMS_GET_ONBOARDING_WIZARD_ALTERNATIVE_MODEM_ADSL: "landing/onboarding/wizards/alternative-modem-adsl",
    CMS_GET_ONBOARDING_WIZARD_ALTERNATIVE_MODEM_VDSL: "landing/onboarding/wizards/alternative-modem-vdsl",
    CMS_GET_ONBOARDING_DEVICES: "landing/onboarding/devices/all",
    CMS_GET_ONBOARDING_PRODUCT_CARDS: "landing/onboarding/product-cards",
    CMS_GET_ONBOARDING_STEPPERCARDS_TECH: "landing/onboarding/dashboard/steppercards/all",
    CMS_GET_ONBOARDING_ERRORS: "landing/onboarding/errors",
    CMS_GET_TECH_APPOINTMENT: "landing/techappointment",
    CMS_GET_TECH_APPOINTMENT_FAQ: "landing/techappointment/faq/all",
    CMS_GET_ONE_CLICK: "landing/one-click",
    CMS_GET_CONTRACT_SUMMARY: "landing/reshaping-offer/sintesi-contrattuali/id-prodotti",
    CMS_GET_MULTI_FACTOR_AUTH: "landing/multi-factor-auth",
    // endregion
    // region PagesAll
    CMS_GET_PAGES_CONSUMER: "consumer/pages/all",
    CMS_GET_PAGES_BUSINESS: "business/pages/all",
    CMS_GET_PAGES_ESHOP_BUSINESS: "eshop-business/pages",
    CMS_GET_PAGES_GRANDIAZIENDE: "grandi-aziende/pages/all",
    CMS_GET_PAGE_BUSINESS_NAVIGATION: "landing/homepage-business/page-navigation",
    CMS_GET_PAGE_GRANDIAZIENDE_NAVIGATION: "landing/homepage-grandiaziende/page-navigation",
    CMS_GET_PAGE_NAVIGATION: "landing/homepage/page-navigation",
    CMS_GET_ALL_LEADS: "leads/all",
    // endregion
    // region DEVICES
    CMS_GET_DEVICE_ALL: "consumer/eshop/devices/all",
    CMS_GET_DEVICE_CHARACTERISTICS: "consumer/global/device-characteristics",
    // endregion
    DXL_ANONYMOUS_LOGIN: "JsLogin/v2/anonymousLogin",
    DXL_GET_ECOMMERCE_CATALOG: "ecommercemanagement/v1/catalog",
    DXL_GET_GEOGRAPHIC_ADDRESS_MANAGEMENT: "geographicaddressmanagement/v1/geographicaddressvalidation",
    DXL_GET_LANDING_PAGE: "configuratormanagement/v1/landingpage",
    DXL_GET_PROFILE: "WebbusLogin/v1/profile",
    DXL_COOKIE_LOGIN: "JsLogin/v2/cookieLogin",
    DXL_CB_SSO_LOGIN: "loginviasso/v1/login",
    LEGACY_LOGIN: "area-utente/fai-da-te/Common/PaginaUnicadiLogin.html",
    IS_LOGGED: "ssoapi/auth/islogged",
    KEEP_ALIVE: "area-utente/KeepAlive",
    DXL_APP_GUEST: "v3/auth/guest",
    DXL_APP_SECURE_IDENTIFY: "v3/auth/secureIdentify",
    DXL_SHOPPING_CART: "ShoppingCart/v2/shoppingCart",
    CMS_GET_COMMON_ERROR: "eshop-business/common/errors",
    CMS_GET_CAPACITY_AND_COLOR: "/devices-info.json",
    /* START - CMS BFL (business fixed line) services */ CMS_BFL_GET_PAGE: "eshop-business/fixed-line",
    CMS_BFL_GET_PRODUCTS: "eshop-business/fixed-line/products/all",
    CMS_BFL_GET_COVERAGE_TOOLS: "eshop-business/fixed-line/common/coverage-tool",
    CMS_BFL_GET_PERSONAL_DATA: "eshop-business/fixed-line/common/personal-data",
    CMS_BFL_GET_COMMON_PLACEHOLDER: "eshop-business/fixed-line/common/placeholders",
    CMS_BFL_GET_COMMON_WHATSAPP: "eshop-business/fixed-line/common/whatsapp",
    CMS_BFL_GET_TECNOLOGIES: "eshop-business/fixed-line/common/technologies/all",
    CMS_BFL_GET_DEVICES: "eshop-business/fixed-line/common/devices/all",
    CMS_BFL_GET_SHOPPING_CART: "eshop-business/fixed-line/common/shopping-cart",
    CMS_BFL_GET_DETAIL_PRODUCTS: "eshop-business/fixed-line/detail-products/all",
    CMS_BFL_GET_ADDONS: "eshop-business/fixed-line/common/addons/all",
    /* END   - CMS BFL (business fixed line) services */ /* START - CMS BM (business mobile) services */ CMS_BML_GET_COMMON_PLACEHOLDER: "/eshop-business/mobile-line/common/placeholders",
    /* END - CMS BM (business mobile) services */ /* START - CMS BM (business mobile-voce) services */ CMS_BVL_GET_PAGE: "eshop-business/mobile-line",
    CMS_BVL_GET_PRODUCTS: "eshop-business/mobile-line/products/all",
    CMS_BVL_GET_ADDONS: "eshop-business/mobile-line/common/addons/all",
    CMS_BVL_GET_DETAIL_PRODUCTS: "eshop-business/mobile-line/detail-products/all",
    CMS_BML_GET_COMMON_WHATSAPP: "eshop-business/mobile-line/common/whatsapp",
    /* END - CMS BM (business mobile-voce) services */ /* START - CMS BM (business mobile-dati) services */ CMS_BDL_GET_PAGE: "eshop-business/dati-line",
    CMS_BDL_GET_PRODUCTS: "eshop-business/dati-line/products/all",
    CMS_BDL_GET_ADDONS: "eshop-business/dati-line/common/addons/all",
    CMS_BDL_GET_DETAIL_PRODUCTS: "eshop-business/dati-line/detail-products/all",
    CMS_BDL_GET_WHATSAPP: "eshop-business/dati-line/common/whatsapp",
    /* END - CMS BM (business mobile-dati) services */ /* START - CMS DS (business device smartphone) services */ CMS_DS_GET_PAGE: "eshop-business/smartphone",
    CMS_DS_GET_DEVICES: "eshop-business/smartphone/products/all",
    CMS_DS_GET_COMMON_PLACEHOLDER: "eshop-business/smartphone/common/placeholders",
    CMS_DS_GET_DETAIL_PRODUCTS: "eshop-business/smartphone/detail-products/all",
    CMS_DS_GET_COMMON_WHATSAPP: "eshop-business/smartphone/common/whatsapp",
    /* END - CMS DS (business device smartphone) services */ /* START - CMS DT (business device tablet) services */ CMS_DT_GET_PAGE: "eshop-business/tablet",
    CMS_DT_GET_DEVICES: "eshop-business/tablet/products/all",
    CMS_DT_GET_COMMON_PLACEHOLDER: "eshop-business/tablet/common/placeholders",
    CMS_DT_GET_DETAIL_PRODUCTS: "eshop-business/tablet/detail-products/all",
    CMS_DT_GET_COMMON_WHATSAPP: "eshop-business/tablet/common/whatsapp",
    /* END - CMS DT (business tablet) services */ /* START - CMS DSIOT (business digital solution iot) services */ CMS_DSIOT_GET_COMMON_WHATSAPP: "eshop-business/ds-line/common/whatsapp",
    CMS_DSIOT_GET_COMMON_PLACEHOLDER: "eshop-business/ds-line/common/placeholders",
    /* END - CMS DSIOT (business digital solution iot) servicies */ /* START - CMS BTO  services */ CMS_BTO_GET_OFFERS_FILTER: "external-plans.offer-mappings",
    CMS_BTO_GET_OFFERS_FILTER_NEW: "external-plans.offer-mappings.json",
    CMS_BTO_GET_PLANS_OPERATOR: "external-plans.operator-country",
    /* END - CMS BTO  services */ // region AWS Services
    AWS_AVAILABLE_TIME_SLOTS: "appointmentmanagement/v1/availabletimeslots",
    AWS_SCHEDULE: "appointmentmanagement/v1/schedule",
    AWS_SEND_OTP: "esimadapter/v1/sendotp",
    AWS_VERIFY_RETRIEVE_DATA: "esimadapter/v1/verifyotptoken",
    AWS_SEND_CONFIRMATION_OTP: "serialconfirmationadapter/v1/sendotp",
    AWS_VERIFY_CONFIRMATION_OTP_TOKEN: "serialconfirmationadapter/v1/verifyotptoken",
    AWS_END_SERIAL_CONFIRMATION: "serialconfirmationadapter/v1/endserialconfirmation",
    // end region
    // START - tariffe estero CBU
    CMS_ABROAD_GET_HOME: "consumer/tariffe-estero/homepage",
    CMS_ABROAD_GET_HOME_V2: "consumer/tariffe-estero/homepage-v2",
    CMS_ABROAD_GET_ROAMING_AEREO: "consumer/tariffe-estero/roaming-aereo",
    CMS_ABROAD_GET_ROAMING_AEREO_V2: "consumer/tariffe-estero/roaming-aereo-v2",
    CMS_ABROAD_GET_PAESE_UE: "consumer/tariffe-estero/paese-europa",
    CMS_ABROAD_GET_PAESE_UE_V2: "consumer/tariffe-estero/paese-europa-v2",
    CMS_ABROAD_GET_DETTAGLIO_PAESE_UE: "consumer/tariffe-estero/dettaglio-paese-europa",
    CMS_ABROAD_GET_DETTAGLIO_PAESE_UE_V2: "consumer/tariffe-estero/dettaglio-paese-europa-v2",
    CMS_ABROAD_GET_DETTAGLIO_PAESE_MONDO: "consumer/tariffe-estero/dettaglio-paese-mondo",
    CMS_ABROAD_GET_DETTAGLIO_PAESE_MONDO_V2: "consumer/tariffe-estero/dettaglio-paese-mondo-v2",
    CMS_ABROAD_GET_DETTAGLIO_PAESE_ENRICHED_V2: "consumer/tariffe-estero/dettaglio-paese-enriched-v2",
    CMS_ABROAD_GET_DETTAGLIO_TEST_EDITINGS_V2: "consumer/tariffe-estero/dettaglio-test-editings-v2",
    CMS_ABROAD_GET_EDITORIAL_CONFIG: "consumer/global/abroad-editorial-configs/all",
    CMS_CTO_GET_PLANS_OPERATOR_CBU: "external-plans.operator-country-cbu",
    // END - tariffe estero CBU
    // START - Get all nations VBI
    CMS_GET_NATIONS_VBI: "nations-vbi",
    // END - Get all nations VBI
    // START - Get all nations VBI
    CMS_GET_NATIONS_CBU: "nations-cbu",
    // END - Get all nations VBI
    // START - CMS CTO Plans
    CMS_CTO_GET_WORLD_OFFERS: "external-plans.offers-world",
    CMS_CTO_GET_OFFERS_FILTER: "external-plans.offers-world-mapping",
    // END - CMS CTO Plans
    // START - CMS page not found
    CMS_PAGE_NOT_FOUND: "eshop-business/404",
    // END - CMS page not found
    /* aradia */ GET_REDIRECT_LINK: "api/v1/GetRedirectLink"
};
