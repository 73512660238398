import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/codebuild/output/src3860616924/src/web-cloud-prod-vf-it/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import ky from "ky";
import { getToken, checkWindow, getItemCookie } from "@vfit/shared/data-access";
import { LoggerInstance } from "@vfit/shared/providers";
import { errorMessages, DxlError, NextError, delay } from "./utils";
import handleEnv from "../handleEnv";
import { checkCustomHeaderNext, deleteMockCustomHeader, getHeadersDxlGatewayCloud, getHeadersNext, getSilentLoginHeadersNext, MOCK_DXL_TOKEN } from "./config.utils";
export var headersNext = {
    "access-control-allow-credentials": "true",
    "access-control-allow-origin": "*",
    "cache-control": "no-cache",
    "content-type": "application/json",
    accept: "application/json, text/plain, */*",
    "accept-encoding": "gzip, deflate, br",
    "accept-language": "en-US,en;q=0.9,it;q=0.8",
    connection: "keep-alive",
    r7flowindicator: "true",
    sunrise: "true"
};
export var awsHeadersAPI = {
    "cache-control": "no-cache",
    "content-type": "application/json"
};
export var mode = "cors";
export var credentials = "include";
export var prefixUrl = handleEnv("NX_NEXT_ENVIRO") || "";
export var prefixUrlDXL = "NX_DXL_PREFIX" || "";
export var prefixUrlJourneyAppDXL = handleEnv("NX_DXL_APP_PREFIX") || ""; // "https://dxl.vodafone.it/api"
export var prefixUrlJourneyAppDXLBS = handleEnv("NX_DXL_BS_APP_PREFIX") || ""; // "https://bs.dxl.vodafone.it/api"
export var prefixUrlDXLConsumerCloud = handleEnv("NX_DXL_CONSUMER_CLOUD") || "";
export var prefixUrlDXLGatewayCloud = handleEnv("NX_DXL_GATEWAY_CLOUD") || "";
export var prefixUrlCMS = handleEnv("NX_CMS") || "";
export var prefixUrlWebApi = handleEnv("NX_WEBAPI_SERVERLESS") || "";
export var prefixUrlAwsApiConsumer = handleEnv("NX_AWSAPI_CONSUMER_PREFIX") || "";
export var isRequiredCustomHeaders = handleEnv("NODE_ENV") !== "development" && handleEnv("NX_TEST_QA") !== "TRUE";
export var mockApiKey = handleEnv("NX_MOCK_API_KEY");
export var isEnabledMockApiKey = handleEnv("NX_MOCK_API_KEY_ENABLED") === "TRUE";
export var NEXT = _object_spread_props(_object_spread({}, checkCustomHeaderNext()), {
    baseUrl: "",
    prefixUrl: prefixUrl,
    searchParams: {
        salesChannel: "selfService"
    },
    hooks: {
        beforeRequest: [
            function() {
                var _ref = // eslint-disable-next-line consistent-return
                _async_to_generator(regeneratorRuntime.mark(function _callee(request, options) {
                    var urlObject, urlPath, headerLogger, token, SILENT_LOGIN, URLService, res, auth, ref;
                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                        while(1)switch(_ctx.prev = _ctx.next){
                            case 0:
                                if (!(handleEnv("NODE_ENV") === "LOCAL" || handleEnv("NODE_ENV") === "development")) {
                                    _ctx.next = 3;
                                    break;
                                }
                                _ctx.next = 3;
                                return delay(1000);
                            case 3:
                                urlObject = new URL(request.url);
                                urlPath = urlObject.pathname.replace("/api/next/", "");
                                headerLogger = _object_spread({}, getHeadersNext());
                                if (!checkWindow()) {
                                    _ctx.next = 11;
                                    break;
                                }
                                token = getToken("uxfauthorization");
                                if (!token) {
                                    _ctx.next = 11;
                                    break;
                                }
                                LoggerInstance.info("".concat(urlPath, " REQ. HEADERS: "), headerLogger);
                                return _ctx.abrupt("return", request.headers.set("Authorization", token));
                            case 11:
                                SILENT_LOGIN = "/authentication/external-user/v1/silentLogin?salesChannel=selfService";
                                URLService = handleEnv("NX_NEXT_ENVIRO");
                                LoggerInstance.debug("API Request SILENT LOGIN:", request === null || request === void 0 ? void 0 : request.url);
                                _ctx.next = 16;
                                return ky.post(URLService + SILENT_LOGIN, {
                                    json: {},
                                    headers: _object_spread({}, getHeadersNext(), options.silentLoginHeaders && getSilentLoginHeadersNext(options === null || options === void 0 ? void 0 : options.silentLoginHeaders)),
                                    retry: 0
                                }).catch(function(error) {
                                    return console.error("catch error beforeRequest", error, "post at urlService: ", URLService);
                                });
                            case 16:
                                res = _ctx.sent;
                                if (res) {
                                    auth = res.headers.get("uxfauthorization");
                                    if (auth && checkWindow()) {
                                        ;
                                        sessionStorage.setItem("uxfauthorization", auth);
                                        sessionStorage.setItem("uxfpath", (window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.href) || "");
                                        request.headers.set("Authorization", auth);
                                    }
                                }
                                request.headers.forEach(function(value, name) {
                                    var newProperty = value;
                                    headerLogger[newProperty] = name;
                                });
                                deleteMockCustomHeader(request.headers);
                                LoggerInstance.info("".concat(urlPath, " REQ. HEADERS: "), headerLogger);
                            case 21:
                            case "end":
                                return _ctx.stop();
                        }
                    }, _callee);
                }));
                return function(request, options) {
                    return _ref.apply(this, arguments);
                };
            }(), 
        ],
        afterResponse: [
            function() {
                var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(request, _options, response) {
                    var body, auth, responseHeaderLogger, urlObject, urlPath;
                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                        while(1)switch(_ctx.prev = _ctx.next){
                            case 0:
                                if (response.ok) {
                                    _ctx.next = 2;
                                    break;
                                }
                                throw new NextError(response, request).getError();
                            case 2:
                                _ctx.prev = 2;
                                if (!(response.status !== 204)) {
                                    _ctx.next = 9;
                                    break;
                                }
                                _ctx.next = 6;
                                return response.json();
                            case 6:
                                _ctx.t0 = _ctx.sent;
                                _ctx.next = 10;
                                break;
                            case 9:
                                _ctx.t0 = {};
                            case 10:
                                body = _ctx.t0;
                                if (!body.error) {
                                    _ctx.next = 13;
                                    break;
                                }
                                throw new Error("throw body error ".concat(JSON.stringify(body.error)));
                            case 13:
                                auth = response.headers.get("uxfauthorization");
                                if (auth && !_options.ignoreUpdateToken) {
                                    LoggerInstance.debug("New Auth", auth);
                                    sessionStorage.setItem("uxfauthorization", auth);
                                }
                                _ctx.next = 20;
                                break;
                            case 17:
                                _ctx.prev = 17;
                                _ctx.t1 = _ctx["catch"](2);
                                LoggerInstance.debug("Response is not a json");
                            case 20:
                                responseHeaderLogger = {};
                                response.headers.forEach(function(name, value) {
                                    responseHeaderLogger[value] = name;
                                });
                                urlObject = new URL(request.url);
                                urlPath = urlObject.pathname.replace("/api/next/", "");
                                LoggerInstance.info("".concat(urlPath, " RES. HEADERS: "), responseHeaderLogger);
                            case 25:
                            case "end":
                                return _ctx.stop();
                        }
                    }, _callee, null, [
                        [
                            2,
                            17
                        ]
                    ]);
                }));
                return function(request, _options, response) {
                    return _ref.apply(this, arguments);
                };
            }(), 
        ]
    },
    timeout: 120000,
    retry: 0
});
export var DXL = {
    baseUrl: "",
    headers: {
        "X-Device-UserAgent": 'MyVodafone/android/s/7.1.2/12.19.0/1.5/custom/"samsung"/"SM-N950N"',
        "X-Device-Id": "13f3165b460c43f59fddeae8ab9ec02f",
        clientId: "4002"
    },
    /**
   * @author andrea dattola
   IN MY OPINION, A GENERAL ENV VARIABLE SHOULD BE ADDED FOR THE PREFIX OF DXL, SO AS TO HANDLE THE PREFIXURL AS FOLLOWS

   prefixUrl:handleEnv('NX_DXL_ENVIRO' as ENV), //todo check for env variable for dxl client
   */ prefixUrl: prefixUrlDXL,
    searchParams: {},
    hooks: {
        beforeRequest: [
            function(request) {
                if (checkWindow()) {
                    var ref;
                    var token = getToken("dxl-jwt");
                    if (!(request === null || request === void 0 ? void 0 : (ref = request.url) === null || ref === void 0 ? void 0 : ref.includes("auth/guest")) && !token) {
                        throw new DxlError("401|_|'AUTH_EXPIRED'|_|'La sessione \xe8 scaduta, ricarica la pagina'");
                    }
                    if (token) request.headers.set("X-Auth-Token", token);
                }
            }, 
        ],
        afterResponse: [
            function() {
                var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(_request, _options, response) {
                    var res, mappedMessage;
                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                        while(1)switch(_ctx.prev = _ctx.next){
                            case 0:
                                if (!(handleEnv("NODE_ENV") === "LOCAL" && checkWindow())) {
                                    _ctx.next = 3;
                                    break;
                                }
                                _ctx.next = 3;
                                return delay(1000);
                            case 3:
                                if (response.ok) {
                                    _ctx.next = 5;
                                    break;
                                }
                                throw new DxlError("".concat(response.status, "|_|").concat(response.statusText || "UNKNOWN_ERROR_RESPONSE_NOT_OK", "|_|Ops, si \xe8 verificato un errore."));
                            case 5:
                                _ctx.prev = 5;
                                if (!(response.status !== 204)) {
                                    _ctx.next = 12;
                                    break;
                                }
                                _ctx.next = 9;
                                return response.json();
                            case 9:
                                _ctx.t0 = _ctx.sent;
                                _ctx.next = 13;
                                break;
                            case 12:
                                _ctx.t0 = {};
                            case 13:
                                res = _ctx.t0;
                                if (!(res.code && res.code !== 0)) {
                                    _ctx.next = 17;
                                    break;
                                }
                                mappedMessage = errorMessages.customErrors.find(function(x) {
                                    return x.code === res.code;
                                });
                                throw new DxlError("".concat(res.code, "|_|").concat(res.description || "UNKNOWN_ERROR_FROM_API", "|_|").concat((mappedMessage === null || mappedMessage === void 0 ? void 0 : mappedMessage.message) || res.message || "Ops, si \xe8 verificato un errore."));
                            case 17:
                                _ctx.next = 22;
                                break;
                            case 19:
                                _ctx.prev = 19;
                                _ctx.t1 = _ctx["catch"](5);
                                LoggerInstance.debug("Response is not a json");
                            case 22:
                            case "end":
                                return _ctx.stop();
                        }
                    }, _callee, null, [
                        [
                            5,
                            19
                        ]
                    ]);
                }));
                return function(_request, _options, response) {
                    return _ref.apply(this, arguments);
                };
            }(), 
        ]
    }
};
export var DXL_JOURNEY_APP = {
    headers: {},
    baseUrl: "",
    prefixUrl: prefixUrlJourneyAppDXL,
    searchParams: {},
    hooks: {
        beforeRequest: [
            function(request) {
                LoggerInstance.debug("dxlClient beforeRequest : ", request);
                if ("object" !== "undefined") {
                    var token = window.sessionStorage.getItem("dxlJwt");
                    LoggerInstance.debug("dxlClient token : ", token);
                    if (!request.url.includes("auth/guest") && !token) {
                        LoggerInstance.debug("401|_|AUTH_EXPIRED|_|La sessione \xe8 scaduta, ricarica la pagina");
                    }
                    // X-Auth-Token dxlJwt token set on headers DXL client
                    if (token) {
                        LoggerInstance.debug("dxlClient beforeRequest token: ", token);
                        request.headers.set("X-Auth-Token", token);
                    }
                }
            }, 
        ],
        afterResponse: [
            function() {
                var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(_request, _options, response) {
                    var res;
                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                        while(1)switch(_ctx.prev = _ctx.next){
                            case 0:
                                LoggerInstance.debug("dxlClient afterResponse : ", response);
                                if (response.ok) {
                                    _ctx.next = 3;
                                    break;
                                }
                                throw new DxlError("".concat(response.status, "|_|").concat(response.statusText || "UNKNOWN_ERROR_RESPONSE_NOT_OK", "|_|Ops, si \xe8 verificato un errore."));
                            case 3:
                                _ctx.prev = 3;
                                if (!(response.status !== 204)) {
                                    _ctx.next = 10;
                                    break;
                                }
                                _ctx.next = 7;
                                return response.json();
                            case 7:
                                _ctx.t0 = _ctx.sent;
                                _ctx.next = 11;
                                break;
                            case 10:
                                _ctx.t0 = {};
                            case 11:
                                res = _ctx.t0;
                                if (res.code && res.code !== 0) {
                                    LoggerInstance.debug("".concat(res === null || res === void 0 ? void 0 : res.code, "|_|").concat((res === null || res === void 0 ? void 0 : res.description) || "UNKNOWN_API_ERROR", "|_|").concat((res === null || res === void 0 ? void 0 : res.message) || "Attenzione, si \xe8 verificato un errore"));
                                }
                                _ctx.next = 18;
                                break;
                            case 15:
                                _ctx.prev = 15;
                                _ctx.t1 = _ctx["catch"](3);
                                LoggerInstance.debug("Response is not a json");
                            case 18:
                            case "end":
                                return _ctx.stop();
                        }
                    }, _callee, null, [
                        [
                            3,
                            15
                        ]
                    ]);
                }));
                return function(_request, _options, response) {
                    return _ref.apply(this, arguments);
                };
            }(), 
        ]
    },
    timeout: 60000
};
export var CMS = {
    baseUrl: "",
    headers: {},
    prefixUrl: prefixUrlCMS,
    searchParams: {},
    hooks: {
        beforeRequest: [
            function() {}
        ],
        afterResponse: [
            function() {
                var _ref = // eslint-disable-next-line consistent-return
                _async_to_generator(regeneratorRuntime.mark(function _callee(_request, _options, response) {
                    var body;
                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                        while(1)switch(_ctx.prev = _ctx.next){
                            case 0:
                                if (!response.ok) {
                                    _ctx.next = 8;
                                    break;
                                }
                                _ctx.next = 3;
                                return response.json();
                            case 3:
                                body = _ctx.sent;
                                if (!body.error) {
                                    _ctx.next = 6;
                                    break;
                                }
                                throw new Error("throw body error ".concat(JSON.stringify(body.error)));
                            case 6:
                                LoggerInstance.debug("body response", body);
                                return _ctx.abrupt("return", body);
                            case 8:
                            case "end":
                                return _ctx.stop();
                        }
                    }, _callee);
                }));
                return function(_request, _options, response) {
                    return _ref.apply(this, arguments);
                };
            }(), 
        ]
    },
    timeout: 60000
};
export var WEBAPI_SERVERLESS = {
    baseUrl: "",
    headers: {},
    prefixUrl: prefixUrlWebApi,
    searchParams: "",
    hooks: {
        beforeRequest: [
            function() {}
        ],
        afterResponse: [
            function() {
                var _ref = // eslint-disable-next-line consistent-return
                _async_to_generator(regeneratorRuntime.mark(function _callee(_request, _options, response) {
                    var body;
                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                        while(1)switch(_ctx.prev = _ctx.next){
                            case 0:
                                if (!response) {
                                    _ctx.next = 6;
                                    break;
                                }
                                _ctx.next = 3;
                                return response.json();
                            case 3:
                                _ctx.t0 = _ctx.sent;
                                _ctx.next = 7;
                                break;
                            case 6:
                                _ctx.t0 = {};
                            case 7:
                                body = _ctx.t0;
                                if (response.ok) {
                                    _ctx.next = 10;
                                    break;
                                }
                                throw new NextError(response, _request, body).getError();
                            case 10:
                                _ctx.prev = 10;
                                if (!body.error) {
                                    _ctx.next = 13;
                                    break;
                                }
                                throw new Error("throw body error ".concat(JSON.stringify(body.error)));
                            case 13:
                                return _ctx.abrupt("return", body);
                            case 16:
                                _ctx.prev = 16;
                                _ctx.t1 = _ctx["catch"](10);
                                LoggerInstance.debug("Response is not a json");
                            case 19:
                            case "end":
                                return _ctx.stop();
                        }
                    }, _callee, null, [
                        [
                            10,
                            16
                        ]
                    ]);
                }));
                return function(_request, _options, response) {
                    return _ref.apply(this, arguments);
                };
            }(), 
        ]
    }
};
export var AWSAPI_CONSUMER = {
    baseUrl: "",
    headers: _object_spread({}, awsHeadersAPI),
    timeout: 60000,
    retry: 0,
    prefixUrl: prefixUrlAwsApiConsumer,
    searchParams: "",
    hooks: {
        beforeRequest: [
            function() {
                var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(request) {
                    var jwt;
                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                        while(1)switch(_ctx.prev = _ctx.next){
                            case 0:
                                jwt = sessionStorage.getItem("dxlJwt");
                                if (jwt && jwt != "undefined") {
                                    request.headers.set("vf-a-appconsumerauth", jwt);
                                }
                            case 2:
                            case "end":
                                return _ctx.stop();
                        }
                    }, _callee);
                }));
                return function(request) {
                    return _ref.apply(this, arguments);
                };
            }(), 
        ],
        afterResponse: [
            function() {
                var _ref = // eslint-disable-next-line consistent-return
                _async_to_generator(regeneratorRuntime.mark(function _callee(_request, _options, response) {
                    var isDevLocal, jwtAuth, body, body1;
                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                        while(1)switch(_ctx.prev = _ctx.next){
                            case 0:
                                isDevLocal = handleEnv("NODE_ENV") === "LOCAL" || handleEnv("NODE_ENV") === "development";
                                jwtAuth = response.headers.get("vf-a-appconsumerauth");
                                if (jwtAuth || isDevLocal) {
                                    sessionStorage.setItem("dxlJwt", isDevLocal ? MOCK_DXL_TOKEN : jwtAuth);
                                }
                                if (response.ok) {
                                    _ctx.next = 8;
                                    break;
                                }
                                _ctx.next = 6;
                                return response.json();
                            case 6:
                                body = _ctx.sent;
                                // eslint-disable-next-line @typescript-eslint/no-throw-literal
                                throw new NextError(response, _request, body).getError();
                            case 8:
                                _ctx.prev = 8;
                                _ctx.next = 11;
                                return response.json();
                            case 11:
                                body1 = _ctx.sent;
                                if (!body1.error) {
                                    _ctx.next = 14;
                                    break;
                                }
                                throw new Error("throw body error ".concat(JSON.stringify(body1.error)));
                            case 14:
                                LoggerInstance.debug("body response", body1);
                                return _ctx.abrupt("return", body1);
                            case 18:
                                _ctx.prev = 18;
                                _ctx.t0 = _ctx["catch"](8);
                                LoggerInstance.debug("Response is not a json");
                            case 21:
                            case "end":
                                return _ctx.stop();
                        }
                    }, _callee, null, [
                        [
                            8,
                            18
                        ]
                    ]);
                }));
                return function(_request, _options, response) {
                    return _ref.apply(this, arguments);
                };
            }(), 
        ]
    }
};
export var DXLCLOUD_CONSUMER = {
    baseUrl: "",
    headers: {},
    prefixUrl: prefixUrlDXLConsumerCloud,
    searchParams: "",
    hooks: {
        beforeRequest: [
            function(request) {
                var cookie = getItemCookie("vf-a-customer-identification");
                if (cookie) request.headers.set("vf-a-customer-identification", cookie);
            }, 
        ],
        afterResponse: [
            function() {
                var _ref = // eslint-disable-next-line consistent-return
                _async_to_generator(regeneratorRuntime.mark(function _callee(_request, _options, response) {
                    var body;
                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                        while(1)switch(_ctx.prev = _ctx.next){
                            case 0:
                                if (!response.ok) {
                                    _ctx.next = 8;
                                    break;
                                }
                                _ctx.next = 3;
                                return response.json();
                            case 3:
                                body = _ctx.sent;
                                if (!body.error) {
                                    _ctx.next = 6;
                                    break;
                                }
                                throw new Error("throw body error ".concat(JSON.stringify(body.error)));
                            case 6:
                                LoggerInstance.debug("body response", body);
                                return _ctx.abrupt("return", body);
                            case 8:
                            case "end":
                                return _ctx.stop();
                        }
                    }, _callee);
                }));
                return function(_request, _options, response) {
                    return _ref.apply(this, arguments);
                };
            }(), 
        ]
    }
};
export var DXL_GATEWAY_CLOUD = {
    baseUrl: "",
    headers: getHeadersDxlGatewayCloud(),
    prefixUrl: prefixUrlDXLGatewayCloud,
    searchParams: "",
    hooks: {
        beforeRequest: [
            function() {}
        ],
        afterResponse: [
            function() {
                var _ref = // eslint-disable-next-line consistent-return
                _async_to_generator(regeneratorRuntime.mark(function _callee(request, _options, response) {
                    var body, body1;
                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                        while(1)switch(_ctx.prev = _ctx.next){
                            case 0:
                                if (response.ok) {
                                    _ctx.next = 5;
                                    break;
                                }
                                _ctx.next = 3;
                                return response.json();
                            case 3:
                                body = _ctx.sent;
                                // eslint-disable-next-line @typescript-eslint/no-throw-literal
                                throw new NextError(response, request, body).getError();
                            case 5:
                                _ctx.next = 7;
                                return response.json();
                            case 7:
                                body1 = _ctx.sent;
                                if (!body1.error) {
                                    _ctx.next = 10;
                                    break;
                                }
                                throw new Error("throw body error ".concat(JSON.stringify(body1.error)));
                            case 10:
                                LoggerInstance.debug("body response", body1);
                                return _ctx.abrupt("return", body1);
                            case 12:
                            case "end":
                                return _ctx.stop();
                        }
                    }, _callee);
                }));
                return function(request, _options, response) {
                    return _ref.apply(this, arguments);
                };
            }(), 
        ]
    },
    timeout: 60000
};
