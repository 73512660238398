import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  line-height: 1;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { fonts } from "@vfit/shared/themes";
import { getVariant } from "../../price.style";
export var SpanWrapper = styled.span.withConfig({
    componentId: "sc-731c575d-0"
})(_templateObject(), function(param) {
    var isBlock = param.isBlock;
    return isBlock ? "display: block;" : "";
}, fonts.regular);
export var OldAmount = styled.span.withConfig({
    componentId: "sc-731c575d-1"
})(_templateObject1(), function(param) {
    var variant = param.variant;
    return getVariant("oldAmount", variant);
});
export var Amount = styled.span.withConfig({
    componentId: "sc-731c575d-2"
})(_templateObject2(), function(param) {
    var variant = param.variant;
    return getVariant("amount", variant);
});
export var Recurrence = styled.span.withConfig({
    componentId: "sc-731c575d-3"
})(_templateObject3(), function(param) {
    var variant = param.variant;
    return getVariant("recurrence", variant);
});
export var FreeAmountRecurrence = styled.span.withConfig({
    componentId: "sc-731c575d-4"
})(_templateObject4(), function(param) {
    var variant = param.variant;
    return getVariant("freeAmountRecurrence", variant);
});
